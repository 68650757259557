import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ApiService from "../../../Service/ApiService";

const EditBookingPage = () => {
  const navigate = useNavigate();
  const { bookingCode } = useParams();
  const [bookingDetails, setBookingDetails] = useState(null); // state variable for booking details
  const [error, setError] = useState(null); //track any error
  const [success, setSuccessMessage] = useState(null); // track any error

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await ApiService.getBookingByConfirmationCode(
          bookingCode
        );
        setBookingDetails(response.booking);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchBookingDetails();
  }, [bookingCode]);

  const achieveBooking = async (bookingId) => {
    if (!window.confirm("Are you sure you want to achieve this booking?")) {
      return; //do nothhing if the user cancels
    }
    try {
      const response = await ApiService.cancelBooking(bookingId);
      if (response.statusCode === 200) {
        setSuccessMessage("The booking was successfully achieved");

        setTimeout(() => {
          setSuccessMessage("");
          navigate("/admin/manage-bookings");
        }, 3000);
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      setTimeout(() => setError(""), 5000);
    }
  };

  return (
    <div className="find-booking-page">
      <h2>Booking Detail</h2>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
      {bookingDetails && (
        <div className="booking-details">
          <h3>Booking Details</h3>
          <p>Confirmation Code : {bookingDetails.bookingConfirmationCode}</p>
          <p>Check-in Date: {bookingDetails.checkInDate}</p>
          <p>Check-out Date: {bookingDetails.checkOutDate}</p>
          <p>Num of Adults: {bookingDetails.numOfAdults}</p>
          <p>Num of Children: {bookingDetails.numOfChildren}</p>
          <p>Guest Email: {bookingDetails.user.email}</p>
          <p>
            Booking Status: {bookingDetails.deleted ? "Archived" : "Active"}
          </p>
          <br />
          <hr />
          <br />
          <h3>User who made the booking </h3> {/* booker details*/}
          <div>
            <p>Name: {bookingDetails.user.name}</p>
            <p>Email: {bookingDetails.user.email}</p>
            <p>Phone Number: {bookingDetails.user.phoneNumber}</p>
          </div>
          <br />
          <hr />
          <br />
          <h3>Room Details</h3>
          <div>
            <p>Room Type: {bookingDetails.room.roomType}</p>
            <p>Room Price: {bookingDetails.room.roomPrice}</p>
            <p>Room Description: {bookingDetails.room.roomDescription}</p>
            <img
              src={bookingDetails.room.roomPhotoUrl}
              alt=""
              sizes=""
              srcSet=""
            />
          </div>
          {!bookingDetails.deleted && (
            <button
              className="achieve-booking"
              onClick={() => achieveBooking(bookingDetails.id)}
            >
              {" "}
              Achieve Booking
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default EditBookingPage;

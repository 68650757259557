import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ApiService from "../../Service/ApiService";
import DatePicker from "react-datepicker";
import { AiOutlineArrowLeft } from "react-icons/ai";

const RoomDetailsPage = () => {
  const navigate = useNavigate(); // access the navigate function to navigate
  const { roomId } = useParams(); // get room id from url parameters
  const [roomDetails, setRoomDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // track loading state
  const [error, setError] = useState(null); // track any errors
  const [checkInDate, setCheckInDate] = useState(null); // state variable for check-in date
  const [checkOutDate, setCheckOutDate] = useState(null); // state variable for check-out date
  const [numAdults, setNumAdults] = useState(0); // state variable for num of adults
  const [numChildren, setNumChildren] = useState(0); // state variable for num of children
  const [totalPrice, setTotalPrice] = useState(0); // state variable for total booking price
  const [totalGuests, setTotalGuests] = useState(0); // state variable for total num of guests
  const [showDatePicker, setShowDatePicker] = useState(false); // state variable  to control date picker visibility
  const [userId, setUserId] = useState(""); // state variable to set user id
  const [showMessage, setShowMessage] = useState(false); // state variable to control message visibility
  const [confirmationCode, setConfirmationCode] = useState(""); // state variable for total booking confirmation code
  const [errorMessage, setErrorMessage] = useState(""); // state variable for error message

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // set laoding state to true
        const response = await ApiService.getRoomById(roomId);
        setRoomDetails(response.room);
        const userProfile = await ApiService.getUserProfile();
        setUserId(userProfile.user.id);
      } catch (error) {
        setError(error.response?.data?.message || error.message);
      } finally {
        setIsLoading(false); // set loading state to false after fetching or error
      }
    };
    fetchData();
  }, [roomId]); // Re-run effect when roomId changes

  const handleConfirmBooking = async () => {
    //chheck if check-in and check-out dates are selected
    if (!checkInDate || !checkOutDate) {
      setErrorMessage("Please select check-in and check-out dates.");
      setTimeout(() => setErrorMessage(""), 10000); // clear error message afetr 10 seconds
      return;
    }

    //check if num of adults and children are valid
    if (
      isNaN(numAdults) ||
      numAdults < 1 ||
      isNaN(numChildren) ||
      numChildren < 0
    ) {
      setErrorMessage("Please enter valid number for adults and children.");
      setTimeout(() => setErrorMessage(""), 5000); // clear error message after 5 sec
      return;
    }

    //calculate total num of days
    const oneDay = 24 * 60 * 60 * 1000; // hours *mins*seconds*milliseconds
    const startDate = new Date(checkInDate);
    const endDate = new Date(checkOutDate);
    const totalDays = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1;

    //calculate total num of guests
    const totalGuests = numAdults + numChildren;
    // calculate total price
    const roomPricePerNight = roomDetails.roomPrice;
    const totalPrice = roomPricePerNight * totalDays * totalGuests;

    setTotalPrice(totalPrice);
    setTotalGuests(totalGuests);
  };

  const acceptBooking = async () => {
    try {
      //ensure checkindate and checkoutdate  are date adjust
      const startDate = new Date(checkInDate);
      const endDate = new Date(checkOutDate);

      // convert date yyyy-mm-dd format , adjusting for time zone difference
      const formattedCheckInDate = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
      const formattedCheckOutDate = new Date(
        endDate.getTime() - endDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];

      //create booking object
      const booking = {
        checkInDate: formattedCheckInDate,
        checkOutDate: formattedCheckOutDate,
        numOfAdults: numAdults,
        numOfChildren: numChildren,
      };

      // make booking
      const response = await ApiService.bookRoom(roomId, userId, booking);
      if (response.statusCode === 200) {
        setConfirmationCode(response.bookingConfirmationCode); // set booking confirmation code
        setShowMessage(true); // show messgae
        // hide message and navigate to homepage after 10 sec
        setTimeout(() => {
          setShowMessage(false);
          navigate("/rooms"); //Naviget to rooms
        }, 10000);
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || error.message);
      setTimeout(() => setErrorMessage(""), 5000); // clear error message message
    }
  };
  if (isLoading) {
    return <p className="room-detail-loading]">loading room details...</p>;
  }
  if (error) {
    return <p className="room-detail-loading">{error}</p>;
  }
  if (!roomDetails) {
    return <p className="room-detail-loading">Room not found</p>;
  }
  const { roomType, roomPrice, roomPhotoUrl, description, bookings } =
    roomDetails;

  return (
    <div className="room-details-booking">
      {showMessage && (
        <p className="booking-success-message">
          Booking successful! Confirmation code: {confirmationCode}. An SMS and
          Email of your booking details have been send to you.
        </p>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <div className="back-button">
        <AiOutlineArrowLeft
          onClick={() => navigate(-1)}
          size={25}
          style={{ cursor: "pointer" }}
        />
      </div>
      <h2>Room Details </h2>
      <br />
      <img src={roomPhotoUrl} alt={roomType} className="room-details-image" />
      <div className="room-details-info">
        <h3>{roomType}</h3>
        <p>Price: FBu{roomPrice} / night</p>
        <p>{description}</p>
      </div>
      {bookings && bookings.length > 0 && (
        <div>
          <h3>Existing Booking Details</h3>
          <ul className="booking-list">
            {bookings.map((booking, index) => (
              <li key={booking.id} className="booking-item">
                <span className="booking-number">Booking {index + 1}</span>
                <span className="booking-text">
                  Check-in: {booking.checkInDate}
                </span>
                <span className="booking-text">
                  Check-out: {booking.checkOutDate}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="booking-info">
        <button
          className="book-now-button"
          onClick={() => setShowDatePicker(true)}
        >
          {" "}
          Book Now
        </button>
        {showDatePicker && (
          <button
            className="go-back-button"
            onClick={() => setShowDatePicker(false)}
          >
            {" "}
            Go Back{" "}
          </button>
        )}
        {showDatePicker && (
          <div className="date-picker-container">
            <DatePicker
              className="detail-search-field"
              selected={checkInDate}
              onChange={(date) => setCheckInDate(date)}
              selectsStart
              startDate={checkInDate}
              endDate={checkOutDate}
              placeholderText="Check-in Date"
              dateFormat="dd/MM/yyyy"
            />
            <DatePicker
              className="detail-search-field"
              selected={checkOutDate}
              onChange={(date) => setCheckOutDate(date)}
              selectsEnd
              startDate={checkInDate}
              endDate={checkOutDate}
              minDate={checkInDate}
              placeholderText="Check-out Date"
              dateFormat="dd/MM/yyyy"
            />

            <div className="guest-container">
              <div className="guest-div">
                <label>Adults: </label>
                <input
                  type="number"
                  min="0"
                  value={numAdults}
                  onChange={(e) => setNumAdults(parseInt(e.target.value))}
                />
              </div>

              <div className="guest-div">
                <label>Children: </label>
                <input
                  type="number"
                  min="0"
                  value={numChildren}
                  onChange={(e) => setNumChildren(parseInt(e.target.value))}
                />
              </div>
              <button
                className="confirm-booking"
                onClick={handleConfirmBooking}
              >
                {" "}
                Confirm Booking{" "}
              </button>
            </div>
          </div>
        )}
        {totalPrice > 0 && (
          <div className="total-price">
            <p>Total Price: FBu{totalPrice}</p>
            <p>Total Guests: {totalGuests}</p>
            <button onClick={acceptBooking} className="accept-booking">
              {" "}
              Accept Booking{" "}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RoomDetailsPage;

import React, { useEffect, useState } from "react";
import "./index.css";
import Header from "../../../common/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Input, Select } from "antd";
import ApiService from "../../../../Service/ApiService";
import { notify } from "../../../common/Toast";
const { TextArea } = Input;

const CreateHotel = () => {
  const location = useLocation();
  const [hotel, setHotel] = useState();
  const [admins, setAdmins] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location) {
      setHotel(location.state?.hotel);
      form.setFieldsValue(location.state?.hotel);
    }
    const fetchUsers = async () => {
      let users = await ApiService.getUsersByRole("ADMIN");
      let userOptions = users.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });
      setAdmins(userOptions);
    };
    fetchUsers();
  }, [location]);

  const handleSubmit = async (values) => {
    setLoading(true);
    let result;
    if (hotel) {
      result = await ApiService.updateHotel(hotel.id, values);
    } else {
      result = await ApiService.addHotel(values);
    }
    if (result.statusCode === 201 || result.statusCode === 200) {
      notify(result.message, "success");
      navigate(-1);
    } else {
      console.error(result);
      notify("Could not update Hotel details", "Error");
    }
    setLoading(false);
  };

  return (
    <div className="default-container">
      <Header title={hotel ? "Manage Hotel Details" : "Create a new Hotel"} />
      <Form
        className="form"
        name="hotel-form"
        initialValues={hotel}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          className="form-item"
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input a Hotel name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Address"
          name="address"
          rules={[
            {
              required: true,
              message: "Please input a Hotel address!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please input a Hotel description!",
            },
          ]}
        >
          <TextArea autoSize={true} />
        </Form.Item>
        <Form.Item className="form-item" label="Manager" name="manager">
          <Select options={admins} />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            overflow: "hidden",
            margin: "10px",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            className="action-button-default"
            size="large"
            disabled={loading}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CreateHotel;

import React, { useState } from "react";
import { FaBars } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import ApiService from "../../../Service/ApiService";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";

const Navbar = ({ title, links }) => {
  const [active, setActive] = useState("home");
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const isAuthenticated = ApiService.isAuthenticated();
  const isAdmin = ApiService.isAdmin();
  const isUser = ApiService.isUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    const isLogout = window.confirm("Are you sure you really want to logout");
    if (isLogout) {
      ApiService.logout();
      navigate("/home");
    }
  };

  const toggleMenu = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const navigateToPage = (url) => {
    if (url === "logout") {
      handleLogout();
      return;
    }
    setActive(url);
    navigate(url);
    setIsNavbarOpen(false);
  };
  return (
    <>
      <header className="navbar__header">
        <div className="navbar__container">
          <nav>
            <div className="logo">
              <h2>{title}</h2>
            </div>
            <ul
              className={`navbar__links ${isNavbarOpen ? "active" : undefined}`}
            >
              {links.map((link, idx) => {
                if (link.alwaysAppear) {
                  return (
                    <li key={idx}>
                      <div
                        className={`navbar__link-item ${
                          active === link.url ? "active" : undefined
                        }`}
                        onClick={() => navigateToPage(link.url)}
                      >
                        {link.title}
                      </div>
                    </li>
                  );
                } else if (link.requireAuth && isAuthenticated) {
                  if (link.requireAdmin && isAdmin) {
                    return (
                      <li key={idx}>
                        <div
                          className={`navbar__link-item ${
                            active === link.url ? "active" : undefined
                          }`}
                          onClick={() => navigateToPage(link.url)}
                        >
                          {link.title}
                        </div>
                      </li>
                    );
                  } else if (link.requireUser && isUser) {
                    return (
                      <li key={idx}>
                        <div
                          className={`navbar__link-item ${
                            active === link.url ? "active" : undefined
                          }`}
                          onClick={() => navigateToPage(link.url)}
                        >
                          {link.title}
                        </div>
                      </li>
                    );
                  } else if (!link.requireAdmin && !link.requireUser) {
                    return (
                      <li key={idx}>
                        <div
                          className={`navbar__link-item ${
                            active === link.url ? "active" : undefined
                          }`}
                          onClick={() => navigateToPage(link.url)}
                        >
                          {link.title}
                        </div>
                      </li>
                    );
                  }
                } else if (!link.requireAuth && !isAuthenticated) {
                  return (
                    <li key={idx}>
                      <div
                        className={`navbar__link-item ${
                          active === link.url ? "active" : undefined
                        }`}
                        onClick={() => navigateToPage(link.url)}
                      >
                        {link.title}
                      </div>
                    </li>
                  );
                } else {
                  return;
                }
              })}
            </ul>
            <div className="navbar__icon" onClick={toggleMenu}>
              {isNavbarOpen ? <FaTimes /> : <FaBars />}
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Navbar;

import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import FooterComponent from "./Component/common/Footer";
import Navbar from "./Component/common/Navbar/Navbar";
import HomePage from "./Component/home/HomePage";
import AllRoomsPage from "./Component/booking_room/AllRoomsPage";
import FindBookingPage from "./Component/booking_room/FindBookingPage";
import RoomDetailPage from "./Component/booking_room/RoomDetailsPage";
import LoginPage from "./Component/auth/LoginPage";
import RegisterPage from "./Component/auth/RegisterPage";
import ProfilePage from "./Component/profile/ProfilePage";
import EditProfilePage from "./Component/profile/EditProfilePage";
import { ProtectedRoute, AdminRoute } from "./Service/guard";
import ManageRoomPage from "./Component/admin/ManageRoomPage";
import AdminPage from "./Component/admin/AdminPage";
import AddRoomPage from "./Component/admin/AddRoomPage";
import EditRoomPage from "./Component/admin/EditRoomPage";
import { Bounce, ToastContainer } from "react-toastify";
import ViewHotels from "./Component/admin/Hotel/ViewHotels/ViewHotels";
import CreateHotel from "./Component/admin/Hotel/CreateHotel/CreateHotel";
import ManageBookingsPage from "./Component/admin/Booking/ManageBookingsPage";
import EditBookingPage from "./Component/admin/Booking/EditBookingPage";
import ViewUsers from "./Component/admin/Users/ViewUsers";
import CreateUser from "./Component/admin/Users/CreateUser";

function App() {
  const links = [
    {
      title: "Home",
      url: "/",
      alwaysAppear: true,
      requireAuth: false,
      requireAdmin: false,
      requireUser: false,
    },
    {
      title: "Rooms",
      url: "rooms",
      alwaysAppear: true,
      requireAuth: false,
      requireAdmin: false,
      requireUser: false,
    },
    {
      title: "My Bookings",
      url: "find-booking",
      alwaysAppear: true,
      requireAuth: false,
      requireAdmin: false,
      requireUser: false,
    },
    {
      title: "Profile",
      url: "profile",
      requireAuth: true,
      requireAdmin: false,
      requireUser: false,
    },
    {
      title: "Admin",
      url: "admin",
      requireAuth: true,
      requireAdmin: true,
      requireUser: false,
    },
    {
      title: "Login",
      url: "login",
      requireAuth: false,
      requireAdmin: false,
      requireUser: false,
    },
    {
      title: "Register",
      url: "register",
      requireAuth: false,
      requireAdmin: false,
      requireUser: false,
    },
    {
      title: "Logout",
      url: "logout",
      requireAuth: true,
      requireAdmin: false,
      requireUser: false,
    },
  ];
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar title={"Alexestel Hotels"} links={links} />
        <div className="content">
          <Routes>
            {/* public routes */}
            <Route exact path="/" element={<HomePage />} />
            <Route path="/rooms" element={<AllRoomsPage />} />
            <Route path="/find-booking" element={<FindBookingPage />} />
            <Route exact path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />

            {/* authenticated users routes */}
            <Route
              path="/room-details-book/:roomId"
              element={<ProtectedRoute element={<RoomDetailPage />} />}
            />
            <Route
              path="/profile"
              element={<ProtectedRoute element={<ProfilePage />} />}
            />
            <Route
              path="/edit-profile"
              element={<ProtectedRoute element={<EditProfilePage />} />}
            />

            {/* Admin Auth Routes */}
            <Route
              path="/admin"
              element={<AdminRoute element={<AdminPage />} />}
            />
            <Route
              path="/admin/manage-rooms"
              element={<AdminRoute element={<ManageRoomPage />} />}
            />
            <Route
              path="/admin/manage-bookings"
              element={<AdminRoute element={<ManageBookingsPage />} />}
            />
            <Route
              path="/admin/add-room"
              element={<AdminRoute element={<AddRoomPage />} />}
            />
            <Route
              path="/admin/edit-room/:roomId"
              element={<AdminRoute element={<EditRoomPage />} />}
            />
            <Route
              path="/admin/edit-booking/:bookingCode"
              element={<AdminRoute element={<EditBookingPage />} />}
            />

            <Route path="/admin/hotels" element={<ViewHotels />} />
            <Route path="/admin/hotels/edit" element={<CreateHotel />} />

            <Route path="/admin/users" element={<ViewUsers />} />
            <Route path="/admin/users/edit" element={<CreateUser />} />

            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
        <div className="whatsapp">
          <a href="https://wa.me?" target="blank">
            <img src="/assets/images/whatsapp_2.png" className="whatsapp_img" />
          </a>
        </div>
        <FooterComponent />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </BrowserRouter>
  );
}

export default App;

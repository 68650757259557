import React, { useEffect, useState } from "react";
import "./index.css";
import { Table, Space, Modal } from "antd";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { notify } from "../../common/Toast";
import ApiService from "../../../Service/ApiService";
import Header from "../../common/Header";

const ViewUsers = () => {
  const [admins, setAdmins] = useState([]);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const fetchHotels = async () => {
    let users = await ApiService.getUsersByRole("ADMIN");
    let superAdmins = await ApiService.getUsersByRole("SUPERADMIN");
    let combined = users.concat(superAdmins);
    setAdmins(combined);
  };

  useEffect(() => {
    fetchHotels();
  }, []);

  const editHotel = (record) => {
    navigate("/admin/users/edit", { state: { admin: record } });
  };

  const openDeleteModal = (record) => {
    setSelectedUser(record);
    setModalOpen(true);
  };

  const closeDeleteModal = () => {
    setModalOpen(false);
  };

  const handleHotelDelete = async () => {
    if (selectedUser) {
      const result = await ApiService.deleteUser(selectedUser.id);
      if (result.statusCode === 200) {
        notify(result.message, "success");
        await fetchHotels();
        closeDeleteModal();
      }
    } else {
      notify("Please select a user to delete", "error");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditTwoTone
            twoToneColor="#0000ff"
            style={{ cursor: "pointer", fontSize: "16pt" }}
            onClick={() => editHotel(record)}
          />
          {record.role !== "SUPERADMIN" && (
            <DeleteTwoTone
              twoToneColor={"#ff0000"}
              style={{ cursor: "pointer", fontSize: "16pt" }}
              onClick={() => openDeleteModal(record)}
            />
          )}
        </Space>
      ),
    },
  ];
  return (
    <div className="default-container">
      <Header title={"View and Manage Users"} />
      <div className="action-buttons">
        <button
          type="primary"
          className="add-room-button"
          size={"large"}
          onClick={() => navigate("/admin/users/edit")}
        >
          Create User
        </button>
      </div>
      <Table dataSource={admins} columns={columns} rowKey={"id"} />
      <Modal
        title="Delete User?"
        open={modalOpen}
        centered
        onOk={() => handleHotelDelete()}
        onCancel={() => closeDeleteModal()}
      >
        <p>
          Delete this User? This User will no longer have access to the system
        </p>
      </Modal>
    </div>
  );
};

export default ViewUsers;

import React, { useState } from "react";
import ApiService from "../../Service/ApiService";
import { useNavigate } from "react-router-dom";
import { notify } from "../common/Toast";
//import { set } from "react-datepicker/dist/date_utils";

function RegisterPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { name, email, password, phoneNumber } = formData;
    if (!name || !email || !password || !phoneNumber) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      notify("Please fill all the fields.", "error");
      return;
    }
    setLoading(true);
    try {
      //call the register method from ApiServices
      const response = await ApiService.registerUser(formData);
      // check if the response is succcessful
      if (response.statusCode === 200) {
        //clear the form fields after successful registration
        setFormData({
          name: "",
          email: "",
          password: "",
          phoneNumber: "",
        });
        notify("User registered successfully", "success");
        navigate("/login");
      }
    } catch (error) {
      notify(error.response?.data?.message || error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name: </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Email: </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Phone Number: </label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Password: </label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className={loading ? "disabled" : undefined}>
          {loading ? "Please wait..." : "Register"}
        </button>
      </form>
      <p className="register-link">
        Already have an account? <a href="/login"> Login </a>
      </p>
    </div>
  );
}
export default RegisterPage;

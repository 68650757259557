import React, { useState } from "react";
import RoomSearch from "../common/RoomSearch";
import RoomResult from "../common/RoomResult";
import ServiceCard from "../common/Cards/ServiceCard";

const HomePage = () => {
  const [roomSearchResults, setRoomSearchResults] = useState([]);
  const handleSearchResult = (results) => {
    setRoomSearchResults(results);
  };

  return (
    <div className="home">
      {/* HEADER/BANNER ROOM SECTION */}
      <section>
        <header className="header-banner">
          <div className="overlay"></div>
          <img
            src="/assets/images/hotel1.jpg"
            alt="Alexestel Hotels"
            className="header-image"
          />

          <div className="animated-texts-overlay-content">
            <h1>
              Welcome to{" "}
              <span className="alexestel-color"> Alexestel Hotels</span>
            </h1>
            <br />
            <h3>
              The Unforgatable Ultimate Fresh Taste Of Hospitality In Burundi
            </h3>
          </div>
        </header>
      </section>

      {/* SEARCH/FIND AVAILABLE ROOM SECTION */}
      <div
        style={{
          backgroundImage: 'url("/assets/images/hotroom.jpg")',
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <RoomSearch handleSearchResult={handleSearchResult} />
        <RoomResult roomSearchResults={roomSearchResults} />
      </div>

      <h2 className="home-services">
        Services at <span className="alexestel-color">Alexestel Hotels</span>{" "}
      </h2>

      {/* SERVICES SECTION */}
      <section className="service-section">
        <ServiceCard
          image={"/assets/images/accommodation.png"}
          title={"Accommodation"}
          description={
            "We have cool and comfortable place to spend as you rejuvinate your energy"
          }
        />
        <ServiceCard
          image={"/assets/images/air-conditioner.png"}
          title={"Air Conditioning"}
          description={"Enjoy the cool breeze in all our rooms"}
        />
        <ServiceCard
          image={"/assets/images/bar.png"}
          title={"Bar and Restaurant"}
          description={
            "We got the most healthy aand delicious food to enjoy over the drink of your choice"
          }
        />
        <ServiceCard
          image={"/assets/images/parking.png"}
          title={"Parking"}
          description={"Ample and secure parking available"}
        />
        <ServiceCard
          image={"/assets/images/wifi-signal.png"}
          title={"Wi-FI"}
          description={
            "Stay connected everywhere within the hotel throughout your stay"
          }
        />
        <ServiceCard
          image={"/assets/images/swimming-pool.png"}
          title={"Swimming Pool"}
          description={
            "Enjoy swimming in our state of the art swimming pools, with clean, regularly refreshed water"
          }
        />
        <ServiceCard
          image={"/assets/images/airport-shuttle.png"}
          title={"Airport Shuttle"}
          description={
            "Enjoy fast, convenient and comfortable rides to and from the airport"
          }
        />
        <ServiceCard
          image={"/assets/images/conference.png"}
          title={"Conference Halls"}
          description={
            "We have large conference halls to host meetings and any kinds of events"
          }
        />
        <ServiceCard
          image={"/assets/images/fitness.png"}
          title={"Fitness"}
          description={
            "Our state of the art gym space will ensure you have only the best equipment to exercise and chase away those calories!"
          }
        />
      </section>
    </div>
  );
};
export default HomePage;

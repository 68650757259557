const FooterComponent = () => {
  return (
    <footer>
      <span className="my-footer">
        
       Alexestel Hotel | All Rights Reserved &copy; {new Date().getFullYear()}
      </span>
    </footer>
  );
};
export default FooterComponent;

import React from "react";
import "./Header.css";

const Header = ({ title }) => {
  return (
    <div className="header-container">
      <p className="header-title">{title}</p>
    </div>
  );
};

export default Header;

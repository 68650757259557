import React, { useEffect, useState } from "react";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Input, Select } from "antd";
import Header from "../../common/Header";
import ApiService from "../../../Service/ApiService";
import { notify } from "../../common/Toast";

const CreateUser = () => {
  const location = useLocation();
  const [user, setUser] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const roles = [
    { label: "Admin", value: "ADMIN" },
    { label: "Super Admin", value: "SUPERADMIN" },
  ];

  useEffect(() => {
    if (location) {
      setUser(location.state?.admin);
      form.setFieldsValue(location.state?.admin);
    }
  }, [location]);

  const handleSubmit = async (values) => {
    setLoading(true);
    let result = await ApiService.saveUser(values);

    if (result.statusCode === 201 || result.statusCode === 200) {
      notify(result.message, "success");
      navigate(-1);
    } else {
      console.error(result);
      notify("Could not update Hotel details", "Error");
    }
    setLoading(false);
  };

  return (
    <div className="default-container">
      <Header title={user ? "Manage User Details" : "Create a new User"} />
      <Form
        className="form"
        name="user-form"
        initialValues={user}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item className="form-item" label="Id" name="id" hidden={true}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input a user name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input a valid user email address",
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Phone Number"
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: "Please input a phone number!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item className="form-item" label="Role" name="role">
          <Select options={roles} />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            overflow: "hidden",
            margin: "10px",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            className="action-button-default"
            size="large"
            disabled={loading}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CreateUser;

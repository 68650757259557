import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../../Service/ApiService";

const AddRoomPage = () => {
  const navigate = useNavigate();
  const [roomDetails, setRoomDetails] = useState({
    roomPhotoUrl: "",
    //roomId: '',
    roomType: "",
    roomPrice: "",
    roomDescription: "",
    hotel: 0,
  });

  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [roomTypes, setRoomTypes] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [newRoomType, setNewRoomType] = useState(false);

  useEffect(() => {
    const fetchRoomTypes = async () => {
      try {
        const types = await ApiService.getRoomTypes();
        const hotels = await ApiService.fetchHotels();
        setRoomTypes(types);
        let hotelSelectOptions = hotels.map((hotel) => {
          return {
            label: hotel.name,
            value: hotel.id,
          };
        });
        setHotels(hotelSelectOptions);
      } catch (error) {
        console.error("Error fetching room types:", error.message);
      }
    };
    fetchRoomTypes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoomDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRoomTypeChange = (e) => {
    if (e.target.value === "new") {
      setNewRoomType(true);
      setRoomDetails((prevState) => ({ ...prevState, roomType: "" }));
    } else {
      setNewRoomType(false);
      setRoomDetails((prevState) => ({
        ...prevState,
        roomType: e.target.value,
      }));
    }
  };

  const handleHotelChange = (e) => {
    setRoomDetails((prevState) => ({ ...prevState, hotel: e.target.value }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreview(URL.createObjectURL(selectedFile));
    } else {
      setFile(null);
      setPreview(null);
    }
  };

  const addRoom = async () => {
    if (
      !roomDetails.roomType ||
      /*!roomDetails.roomId ||*/ !roomDetails.roomPrice ||
      !roomDetails.roomDescription ||
      roomDetails.hotel === 0
    ) {
      setError("All room details must be provided.");
      setTimeout(() => setError(""), 5000);
      return;
    }

    if (!window.confirm("Do you want to add this room?")) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("roomType", roomDetails.roomType);
      //formData.append('roomId', roomDetails.roomId);
      formData.append("roomPrice", roomDetails.roomPrice);
      formData.append("roomDescription", roomDetails.roomDescription);
      formData.append("hotelId", roomDetails.hotel);

      if (file) {
        formData.append("photo", file);
      }

      const result = await ApiService.addRoom(formData);
      if (result.statusCode === 200) {
        setSuccess("Room added successfully.");
        setTimeout(() => {
          setSuccess("");
          navigate("/admin/manage-rooms");
        }, 3000);
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      setTimeout(() => setError(""), 5000);
    }
  };

  return (
    <div className="edit-room-container">
      <h2>Add New Room</h2>
      {error && <p className="error-message">{error}</p>}
      {error && <p className="success-message">{success}</p>}
      <div className="form-group">
        {preview && (
          <img
            src={preview}
            alt="Room Preview"
            className="room-photo-preview"
          />
        )}
        <input type="file" name="roomPhoto" onChange={handleFileChange} />
      </div>

      <div className="form-group">
        <label>Hotel</label>
        <select value={roomDetails.hotel} onChange={handleHotelChange}>
          <option value=""> Select Hotel </option>
          {hotels.map((hotel, idx) => (
            <option key={idx} value={hotel.value}>
              {" "}
              {hotel.label}{" "}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Room Type</label>
        <select value={roomDetails.roomType} onChange={handleRoomTypeChange}>
          <option value=""> Select a room type </option>
          {roomTypes.map((type) => (
            <option key={type} value={type}>
              {" "}
              {type}{" "}
            </option>
          ))}
          <option value="new"> Other (please specify)</option>
        </select>
        {newRoomType && (
          <input
            type="text"
            name="roomType"
            placeholder="Enter new room type"
            value={roomDetails.roomType}
            onChange={handleChange}
          />
        )}
      </div>
      <div className="form-group">
        <label>Room Price</label>
        <input
          type="text"
          name="roomPrice"
          value={roomDetails.roomPrice}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label> Room Description </label>
        <textarea
          name="roomDescription"
          value={roomDetails.roomDescription}
          onChange={handleChange}
        ></textarea>
      </div>
      <button className="update-button" onClick={addRoom}>
        Add Room
      </button>
    </div>
  );
};

export default AddRoomPage;

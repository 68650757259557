import axios from "axios";
export default class ApiService {
  // static BASE_URL = "https://alexestel-api.onrender.com";
  static BASE_URL = process.env.REACT_APP_BASEURL;

  static getHeader() {
    const token = localStorage.getItem("token");
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  }

  /**AUTH */
  /*This register a new user */
  static async registerUser(registration) {
    const response = await axios.post(
      `${this.BASE_URL}/auth/register`,
      registration
    );
    return response.data;
  }
  /*This login a registered user */
  static async loginUser(loginDetails) {
    const response = await axios.post(
      `${this.BASE_URL}/auth/login`,
      loginDetails
    );
    return response.data;
  }

  /**USERS */
  static async getAllUsers() {
    const response = await axios.get(`${this.BASE_URL}/users/all`, {
      headers: this.getHeader(),
    });
    return response.data?.userList ?? [];
  }
  static async getUsersByRole(role) {
    const response = await axios.get(`${this.BASE_URL}/users/all/${role}`, {
      headers: this.getHeader(),
    });
    return response.data?.userList ?? [];
  }
  static async getUserProfile() {
    const response = await axios.get(
      `${this.BASE_URL}/users/get-logged-in-profile-info`,
      {
        headers: this.getHeader(),
      }
    );
    return response.data;
  }

  /*This is to get a siingle user */
  static async getUser(userId) {
    const response = await axios.get(
      `${this.BASE_URL}/users/get-by-id/${userId}`,
      {
        headers: this.getHeader(),
      }
    );
    return response.data;
  }
  /* This is to get user bookings by user id */
  static async getUserBookings(userId) {
    const response = await axios.get(
      `${this.BASE_URL}/users/get-user-bookings/${userId}`,
      {
        headers: this.getHeader(),
      }
    );
    return response.data;
  }
  /**save/update user details */
  static async saveUser(values) {
    const response = await axios.post(`${this.BASE_URL}/users/save`, values, {
      headers: this.getHeader(),
    });
    return response.data;
  }

  static async updateUser(userId, values) {
    const response = await axios.put(
      `${this.BASE_URL}/users/update/${userId}`,
      values,
      {
        headers: this.getHeader(),
      }
    );

    return response.data;
  }
  /*This is to delete a user */
  static async deleteUser(userId) {
    const response = await axios.delete(
      `${this.BASE_URL}/users/delete/${userId}`,
      {
        headers: this.getHeader(),
      }
    );
    return response.data;
  }
  /**Hotel */
  /**Fetch all hotels */
  static async fetchHotels() {
    const result = await axios.get(`${this.BASE_URL}/hotels/getAll`, {
      headers: this.getHeader(),
    });
    return result.data.hotels;
  }

  static async addHotel(formData) {
    const result = await axios.post(`${this.BASE_URL}/hotels/add`, formData, {
      headers: {
        ...this.getHeader(),
      },
    });
    return result.data;
  }

  static async updateHotel(id, formData) {
    const result = await axios.post(
      `${this.BASE_URL}/hotels/update/${id}`,
      formData,
      {
        headers: {
          ...this.getHeader(),
        },
      }
    );
    return result.data;
  }

  static async deleteHotel(id) {
    const result = await axios.post(
      `${this.BASE_URL}/hotels/delete/${id}`,
      {},
      {
        headers: {
          ...this.getHeader(),
        },
      }
    );
    return result.data;
  }
  /**ROOM */
  /*This adds a new room to the database*/
  static async addRoom(formData) {
    const result = await axios.post(`${this.BASE_URL}/rooms/add`, formData, {
      headers: {
        ...this.getHeader(),
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data;
  }
  /*This gets all available rooms*/
  static async getAllAvailableRooms() {
    const result = await axios.get(
      `${this.BASE_URL}/rooms/all-available-rooms`
    );
    return result.data;
  }
  /* This get all room types from the databse*/
  static async getAvailableRoomsByDateAndType(
    checkInDate,
    checkOutDate,
    roomType
  ) {
    const result = await axios.get(
      `${this.BASE_URL}/rooms/available-rooms-by-date-and-type?checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&roomType=${roomType}`
    );
    return result.data;
  }
  /* This gets all rooms from database*/
  static async getRoomTypes() {
    const response = await axios.get(`${this.BASE_URL}/rooms/types`);
    return response.data;
  }
  static async getAllRooms() {
    const result = await axios.get(`${this.BASE_URL}/rooms/all`);
    return result.data;
  }
  /*This function get a room by the id*/
  static async getRoomById(roomId) {
    const result = await axios.get(
      `${this.BASE_URL}/rooms/room-by-id/${roomId}`
    );
    return result.data;
  }
  static async getAllAdminRooms() {
    const result = await axios.get(`${this.BASE_URL}/rooms/alladmin`, {
      headers: this.getHeader(),
    });
    return result.data;
  }
  /* this deletes a room by Id*/
  static async deleteRoom(roomId) {
    const result = await axios.delete(
      `${this.BASE_URL}/rooms/delete/${roomId}`,
      {
        headers: this.getHeader(),
      }
    );
    return result.data;
  }
  static async updateRoom(roomId, formData) {
    const result = await axios.put(
      `${this.BASE_URL}/rooms/update/${roomId}`,
      formData,
      {
        headers: {
          ...this.getHeader(),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data;
  }
  /**BOOKING */
  /*This saves a new booking to the database*/
  static async bookRoom(roomId, userId, booking) {
    const response = await axios.post(
      `${this.BASE_URL}/bookings/book-room/${roomId}/${userId}`,
      booking,
      {
        headers: this.getHeader(),
      }
    );
    return response.data;
  }
  /* This gets all bookings from the database*/
  static async getAllBookings() {
    const result = await axios.get(`${this.BASE_URL}/bookings/all`, {
      headers: this.getHeader(),
    });
    return result.data;
  }

  /* This get booking by the confirmation code */
  static async getBookingByConfirmationCode(bookingCode) {
    const result = await axios.get(
      `${this.BASE_URL}/bookings/get-booking-by-confirmation-code/${bookingCode}`,
      { headers: this.getHeader() }
    );
    return result.data;
  }
  /* This is to cancel user booking */
  static async cancelBooking(bookingId) {
    const result = await axios.post(
      `${this.BASE_URL}/bookings/cancel/${bookingId}`,
      {},
      {
        headers: this.getHeader(),
      }
    );
    return result.data;
  }

  /**AUTHENTICATION CHECKER */
  static logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
  }
  static isAuthenticated() {
    const token = localStorage.getItem("token");
    return !!token;
  }
  static isAdmin() {
    const role = localStorage.getItem("role");
    return role === "ADMIN" || role === "SUPERADMIN";
  }
  static isUser() {
    const role = localStorage.getItem("role");
    return role === "USER";
  }
  static isSuperAdmin() {
    const role = localStorage.getItem("role");
    return role === "SUPERADMIN";
  }
}

import React, { useEffect, useState } from "react";
import "./index.css";
import Header from "../../../common/Header";
import { Button, Table, Space, Modal } from "antd";
import ApiService from "../../../../Service/ApiService";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { notify } from "../../../common/Toast";

const ViewHotels = () => {
  const [hotels, setHotels] = useState([]);
  const [admins, setAdmins] = useState([]);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState();

  const fetchHotels = async () => {
    let users = await ApiService.getUsersByRole("ADMIN");
    let hotels = await ApiService.fetchHotels();

    setAdmins(users);
    setHotels(hotels);
  };

  useEffect(() => {
    fetchHotels();
  }, []);

  const editHotel = (record) => {
    navigate("/admin/hotels/edit", { state: { hotel: record } });
  };

  const openDeleteModal = (record) => {
    setSelectedHotel(record);
    setModalOpen(true);
  };

  const closeDeleteModal = () => {
    setModalOpen(false);
  };

  const handleHotelDelete = async () => {
    if (selectedHotel) {
      const result = await ApiService.deleteHotel(selectedHotel.id);
      if (result.statusCode === 200) {
        notify(result.message, "success");
        await fetchHotels();
        closeDeleteModal();
      }
    } else {
      notify("Please select a hotel to delete", "error");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      render: (_, record) => {
        if (record.manager) {
          let user = admins.filter((x) => x.id === record.manager);
          if (user.length > 0) {
            return user[0].name;
          }
        }
        return "";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditTwoTone
            twoToneColor="#0000ff"
            style={{ cursor: "pointer", fontSize: "16pt" }}
            onClick={() => editHotel(record)}
          />
          <DeleteTwoTone
            twoToneColor={"#ff0000"}
            style={{ cursor: "pointer", fontSize: "16pt" }}
            onClick={() => openDeleteModal(record)}
          />
        </Space>
      ),
    },
  ];
  return (
    <div className="default-container">
      <Header title={"View and Manage Hotels"} />
      <div className="action-buttons">
        <button
          type="primary"
          className="add-room-button"
          size={"large"}
          onClick={() => navigate("/admin/hotels/edit")}
        >
          Create Hotel
        </button>
      </div>
      <Table dataSource={hotels} columns={columns} rowKey={"id"} />
      <Modal
        title="Delete Hotel?"
        open={modalOpen}
        centered
        onOk={() => handleHotelDelete()}
        onCancel={() => closeDeleteModal()}
      >
        <p>
          Delete this hotel? All it's associated rooms and bookings will be
          deleted.
        </p>
      </Modal>
    </div>
  );
};

export default ViewHotels;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../../Service/ApiService";

const AdminPage = () => {
  const [adminName, setAdminName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdminName = async () => {
      try {
        const response = await ApiService.getUserProfile();
        setAdminName(response.user.name);
      } catch (error) {
        console.error("Error fetching admin details: ", error.message);
      }
    };

    fetchAdminName();
  }, []);

  return (
    <div className="admin-page">
      <h1 className="welcome-message">Welcome, {adminName}</h1>
      <div className="admin-actions">
        {ApiService.isSuperAdmin() && (
          <button
            className="admin-button"
            onClick={() => navigate("/admin/hotels")}
          >
            {" "}
            Manage Hotels{" "}
          </button>
        )}
        <button
          className="admin-button"
          onClick={() => navigate("/admin/manage-rooms")}
        >
          {" "}
          Manage Rooms{" "}
        </button>
        <button
          className="admin-button"
          onClick={() => navigate("/admin/manage-bookings")}
        >
          {" "}
          Manage Bookings{" "}
        </button>
        {ApiService.isSuperAdmin() && (
          <button
            className="admin-button"
            onClick={() => navigate("/admin/users")}
          >
            {" "}
            Manage Users{" "}
          </button>
        )}
      </div>
      <div className="hotelView">
        {/**Only admins to view this.....fetch hotel associated to admin */}
      </div>
    </div>
  );
};

export default AdminPage;

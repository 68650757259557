import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../../Service/ApiService";
import { Form, Input } from "antd";
import "./index.css";
import { toast } from "react-toastify";

const EditProfilePage = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await ApiService.getUserProfile();
      setUser(response.user);
      form.setFieldsValue({
        name: response?.user?.name,
        email: response?.user?.email,
        phoneNumber: response?.user?.phoneNumber,
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteProfile = async () => {
    if (!window.confirm("Are you sure you want to delete your account")) {
      return;
    }
    try {
      await ApiService.deleteUser(user.id);
      navigate("/sign-up");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleUpdateProfile = async (values) => {
    setLoading(true);
    try {
      const results = await ApiService.updateUser(user.id, values);
      toast.success(results.message);
      fetchUserProfile();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="edit-profile-page">
      <h2>Edit Profile</h2>
      {error && <p className="error-message">{error}</p>}
      {user && (
        <div className="profile-details">
          <Form
            form={form}
            style={{ height: "100%", width: "100%" }}
            variant="filled"
            onFinish={(values) => handleUpdateProfile(values)}
          >
            <Form.Item
              layout="horizontal"
              label="Name"
              name={"name"}
              rules={[{ required: true, message: "User name is required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              layout="horizontal"
              label="Email"
              name={"email"}
              rules={[
                { required: true, message: "Email Address is required" },
                {
                  type: "email",
                  message: "Please input a valid email address",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              layout="horizontal"
              label="Phone Number"
              name={"phoneNumber"}
              rules={[{ required: true, message: "Phone number is required" }]}
            >
              <Input />
            </Form.Item>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <button
                className="profile-button update-profile-button"
                type="submit"
                disabled={loading}
              >
                {loading ? "Loading..." : "Update Profile"}
              </button>
              <button
                className="profile-button delete-profile-button"
                onClick={handleDeleteProfile}
                disabled={loading}
              >
                {loading ? "Loading..." : "Delete Profile"}
              </button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default EditProfilePage;
